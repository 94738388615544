<template>
    <transition name="fade" mode="out-in">
        <card card-body-classes="table-full-width">
            <h4 slot="header" class="card-title">Utilização</h4>
            <div>
                <div class="row" style="float: right">
                    <span style="margin: 0 10pt">
                        <base-input>
                            <el-select class="select-primary mb-3 pagination-select" v-model="filters.type"
                                @change="filterChange">
                                <el-option class="select-primary" v-for="item in filters.filterOptions" :key="item.name"
                                    :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </base-input>
                    </span>
                    <span style="margin: 0 10pt">
                        <base-input v-if="filters.type == 'Type'">
                            <el-select class="select-primary mb-3 pagination-select" v-model="filters.name">
                                <el-option class="select-primary" v-for="item in filters.typeOptions" :key="item.name"
                                    :label="item.Label" :value="item.Key"></el-option>
                            </el-select>
                        </base-input>
                        <base-input v-if="filters.type == 'SapUsage'">
                            <el-input class="select-primary mb-3 pagination-select" v-model="filters.name">
                            </el-input>
                        </base-input>
                        <base-input v-if="filters.type == null">
                            <el-input class="select-primary mb-3 pagination-select" v-model="filters.name" disabled>
                            </el-input>
                        </base-input>
                    </span>
                    <span style="margin: 0 10pt" v-if="filters.type == null">
                        <button class="custom-btn-filter" @click="loadUsages" :disabled="true">
                            Filtrar
                        </button>
                    </span>
                    <span style="margin: 0 10pt" v-else>
                        <button class="custom-btn-filter" @click="loadUsages">
                            Filtrar
                        </button>
                    </span>
                    <span style="margin: 0 10pt">
                        <button class="custom-btn-filter" @click="clearFilter">
                            Limpar filtro
                        </button>
                    </span>
                    <span style="margin: 0 10pt">
                        <button class="custom-btn-add" @click="addNew"
                            v-if="getUserPermission('43_c')">Novo</button>
                    </span>
                </div>
                <el-table :data="usages.Items">
                    <el-table-column label="Tipo de Operação" align="center">
                        <div slot-scope="props">
                            {{ getUsageType(props.row.Type) }}
                        </div>
                    </el-table-column>
                    <el-table-column label="Utilização SAP" align="center" prop="SapUsage" />

                    <el-table-column align="center" label="Ações">
                        <div slot-scope="props">
                            <base-button class="like !important btn-link" type="primary" size="sm" icon
                                @click="detail(props.row.Id)" v-if="getUserPermission('43_c')">
                                <i class="material-icons">mode_edit_outline</i>
                            </base-button>
                            <base-button class="edit btn-link" type="danger" size="sm" icon
                                @click="remove(props.row.Id)" v-if="getUserPermission('43_c')">
                                <i class="material-icons">delete_outline</i>
                            </base-button>
                        </div>
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-between flex-wrap">
                <div class="col-5"></div>
                <div class="col-2">
                    <label>
                        Exibindo
                        <span class="primary-text">{{ from + 1 }}</span> -
                        <span class="primary-text">{{ to }}</span> de
                        <span class="primary-text">{{ total }}</span> registros
                    </label>
                </div>
                <div class="col-3">
                    <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                        :per-page="pagination.perPage" :total="total" @input="loadUsages"></base-pagination>
                </div>
                <div class="col-2">
                    <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
                        v-model="pagination.perPage" placeholder="Per page">
                        <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item"
                            :label="item" :value="item"></el-option>
                    </el-select>
                </div>
            </div>
        </card>
    </transition>
</template>
<script>
import swal from "sweetalert2";
import { BasePagination, BaseRadio } from "src/components";
import constants from '@/util/constants';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        BaseRadio,
        BasePagination,
    },
    data() {
        return {
            filters: {
                type: null,
                name: null,
                filterOptions: [
                    { name: "Filtrar por...", value: null },
                    { name: "Tipo de Operação", value: "Type" },
                    { name: "Utilização SAP", value: "SapUsage" }
                ],
                typeOptions: [],
                EmptyOption: []
            },
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
        };
    },
    computed: {
        ...mapState('usage', ['usages']),
        ...mapState(['realm']),
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.usages.Total;
        },
    },
    methods: {
        ...mapActions('usage', ['getUsages', 'removeUsage']),
        async loadUsages() {
            let params = Object.assign(this.filters, this.pagination);
            this.getUsages(params);
        },
        clearFilter() {
            this.filters.name = null;
            this.filters.type = null;
            this.loadUsages();
        },
        detail(id) {
            this.$router.push({ name: "UsageDetail", query: { id: id } })
        },
        addNew() {
            this.$router.push({ name: "UsageCreate" })
        },
        remove(id) {
            swal({
                title: "Gostaria de excluir o registro?",
                text: "Esse processo não poderá ser revertido.",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                cancelButtonText: "Não",
                confirmButtonText: "Sim",
            }).then(async (result) => {
                if (result.value) {
                    await this.delete(id)
                    await this.loadUsages();
                }
            });
        },
        async delete(id) {
            try {
                await this.removeUsage(id);
                this.$showSuccess("O cadastro foi excluido com sucesso");
            } catch (error) {
                this.$showError("Ocorreu um erro ao excluir o cadastro");
            }
        },
        getUsageType(key) {
            return constants.usageTypeOptions.find(a => a.Key === key).Label;
        },
        async filterChange(command) {
            this.filters.name = null;
            switch (command) {
                case "Type":
                    this.filters.typeOptions = constants.usageTypeOptions.filter(type => type.Key != 0);
                    this.filters.typeOptions.sort((a, b) => {
                        if (a.Label < b.Label) {
                            return -1;
                        }
                        if (a.Label > b.Label) {
                            return 1;
                        }
                        return 0;
                    });

                    if (this.realm != 'proxys'){
                        this.filters.typeOptions = this.filters.typeOptions.filter(item => item.Key != 11 && item.Key != 12);
                    }
                    break;
            }
        },
        getUserPermission(role) {
            return this.$keycloak.hasRealmRole(role);
        }
    },
    async mounted() {
        this.loadUsages();
    },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
    {
    opacity: 0;
}
</style>
